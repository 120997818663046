import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';
import StepHeader from '../StepHeader/StepHeader';
import ColorSelect from '../ColorSelect/ColorSelect';
import ShipsInfo from '../ShipsInfo/ShipsInfo';
import { Context as AppContext } from '../../context/AppContext';
import { getPriceWithCurrency, mapColorsFromProducts } from '../../utils/helpers';
import giftImage from './gift.png';
import backmedicImage from './backmedic.png';
import './ProductSelect.sass';

export default function ProductSelect({
  offer,
  orderData,
  onSubmit,
  isOneLineCheckout,
  isOneProductCheckout,
}) {
  const { productsData: products, defaultVariantId, mostPopularProductIds } = offer;
  // eslint-disable-next-line no-restricted-globals
  const parsedQuery = queryString.parse(location.search);
  // eslint-disable-next-line no-restricted-globals
  const [soldOut, setSoldOut] = useState(false);
  // State
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Context
  const {
    state: { selectedColor },
    productColorSelect,
  } = useContext(AppContext);

  useEffect(() => {
    const currentProducts = sortProductsByColor(products);
    const allSoldOut = currentProducts.every((item) => item.soldOut === '1');

    setSoldOut(allSoldOut);
  }, [products, selectedProduct, sortProductsByColor]);

  useEffect(() => {
    let defaultProduct = products[0];

    if (products?.length > 0) {
      selectProduct(defaultProduct);
    }

    if (defaultVariantId) {
      defaultProduct = products.find((item) => item.id === defaultVariantId);
      selectProduct(defaultProduct);
    }

    if (defaultVariantId && !selectedColor && defaultProduct) {
      handleColorSelect({
        name: defaultProduct.color,
        imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
      });
    }

    if (products && defaultProduct) {
      productColorSelect(
        selectedColor
          ? selectedColor
          : {
              name: defaultProduct.color,
              imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
            }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  function getSavingsPercent(oldPrice, price) {
    const decrease = oldPrice - price;
    return `${((decrease / oldPrice) * 100).toFixed()}%`;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sortProductsByColor(items) {
    if (!selectedColor) {
      return items;
    }

    return items.filter((item) => item.color === selectedColor.name);
  }

  function selectProduct(product) {
    if (!product) {
      return;
    }

    setSelectedProduct(product);
    // Retrieve offerId and billingModelId from productDetails
    const productDetail = offer.productDetails[product.id];

    const newMainProductOffer = {
      offer_id: productDetail?.offerId || product.offerId, // Fallback to product.offerId if not found in productDetails
      product_id: product.id,
      billing_model_id: productDetail?.billingModelId || product.billingModelId, // Fallback to product.billingModelId if not found
      price: product.price * 1,
      productQty: product.quantity,
      productName: product.name,
      isMainProduct: true,
    };

    // Update offers array: replace the current main product with the new one
    const updatedOffers = orderData.offers.map((offer) =>
      offer.isMainProduct ? newMainProductOffer : offer
    );

    onSubmit(
      {
        productId: product.id,
        productQty: product.quantity,
        productName: product.name,
        productPrice: product.price,
        shippingId: product.shippingId,
        offers: updatedOffers,
      },
      isOneLineCheckout ? 2 : undefined
    );
  }

  function handleColorSelect(color) {
    productColorSelect(color);

    const newProduct = products.find(
      (item) => item.color === color.name && item.quantity === selectedProduct?.quantity
    );
    selectProduct(newProduct);
  }

  function handleSubmit() {
    onSubmit({}, 2);
  }

  function renderProductPrice(price, quantity, offer) {
    if (parsedQuery.each === '1') {
      return `Only ${getPriceWithCurrency(((price * 1) / quantity) * 1, offer?.locale, offer?.currency)}/ea`;
    }

    if (parseInt(price) > 0) {
      return `Only ${getPriceWithCurrency(price, offer?.locale, offer?.currency)}`;
    }

    return 'Just pay shipping';
  }

  function renderPackage(item, index) {
    if (!selectedProduct) {
      return null;
    }

    if (isOneProductCheckout) {
      return (
        <div
          className={`opcProduct ${
            selectedProduct.id === item.id && products.length > 1 && 'opcProduct--active'
          }`}
          key={index}
          onClick={() => selectProduct(item)}
        >
          <div className='opcProduct-left'>
            <div className='opcProduct__image'>
              <img
                src={selectedColor && selectedColor.name ? selectedColor.imageSrc : offer.productImageSrc}
                alt='product'
              />
              <div className='opcProduct__quantity'>{item.quantity}</div>
            </div>
            <div className='opcProduct__title' dangerouslySetInnerHTML={{ __html: item.title }}></div>
          </div>

          <div className='opcProduct-right'>
            <div className='opcProduct__msrp'>
              {getPriceWithCurrency(item.oldPrice, offer?.locale, offer?.currency)}
            </div>
            <div className='opcProduct__price'>
              {getPriceWithCurrency(item.price, offer?.locale, offer?.currency)}
            </div>
          </div>
        </div>
      );
    }

    const isSoldOut = item.soldOut === '1';
    const productClassName = classNames({
      product: true,
      active: selectedProduct.id === item.id,
      popular: mostPopularProductIds.includes(item.id),
      soldOut: isSoldOut,
    });

    const GiftBlock = ({ item }) => {
      const hasGift = item.name?.toLowerCase().includes('gift');

      if (!item || !hasGift) {
        return null;
      }

      return (
        <div className='product-gift'>
          <div className='product-gift__title'>
            <img src={giftImage} alt='gift' width={25} />
            <span>Includes FREE Gift</span>
          </div>
          <div className='product-gift__more'>
            more info
            <div className='product-gift__info'>
              <img src={backmedicImage} alt='back medic' />
              <div className='product-gift__info-title'>
                <s>$70</s> <b>FREE</b> <span>BackMedic Posture Corrector</span>
              </div>
              <div className='product-gift__info-desc'>
                <p>
                  <i className='fa-solid fa-square-check'></i> Helps with back pain
                </p>
                <p>
                  <i className='fa-solid fa-square-check'></i> Appear 5-10lb lighter
                </p>
                <p>
                  <i className='fa-solid fa-square-check'></i> Easy to use
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={productClassName} key={index} onClick={() => (!isSoldOut ? selectProduct(item) : null)}>
        {mostPopularProductIds.includes(item.id) && <div className='product-header'>Most Popular</div>}

        <div className='product-body'>
          <div className='product__checkbox'>
            <i className='fas fa-dot-circle'></i>
            <i className='far fa-circle'></i>
          </div>
          <div className='product-left'>
            <div className='product__title' dangerouslySetInnerHTML={{ __html: item.title }}></div>
            <GiftBlock item={item} />
            {isSoldOut ? (
              <div className='product__shipping' style={{ color: 'red' }}>
                SOLD OUT
              </div>
            ) : (
              <div className='product__shipping'>
                {item.shippingPrice * 1
                  ? `Shipping: ${getPriceWithCurrency(item.shippingPrice, offer?.locale, offer?.currency)}`
                  : 'FREE SHIPPING'}
              </div>
            )}
          </div>
          {!isSoldOut && (
            <div className='product-right'>
              <div className='product__msrp'>
                MSRP {getPriceWithCurrency(item.oldPrice, offer?.locale, offer?.currency)}
              </div>
              <div className='product__price'>{renderProductPrice(item.price, item.quantity, offer)}</div>
              <div className='product__savings'>
                You saved {getSavingsPercent(item.oldPrice * 1, item.price * 1)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (products.length && selectedProduct) {
    return (
      <section className={`product-select ${isOneProductCheckout && 'product-select--opc'}`}>
        {!isOneProductCheckout && <StepHeader number={1} text='Choose Your Deal' />}
        {sortProductsByColor(products).map(renderPackage)}
        {isOneProductCheckout && (
          <div className='opcProduct__shipping'>
            <b>Shipping:</b>{' '}
            <p>{getPriceWithCurrency(selectedProduct.shippingPrice, offer?.locale, offer?.currency)}</p>
          </div>
        )}
        {selectedColor && selectedColor.name && (
          <ColorSelect
            colors={mapColorsFromProducts(products)}
            onChange={handleColorSelect}
            selectedColor={selectedColor}
            defaultProduct={products.find((item) => item.id === defaultVariantId)}
          />
        )}

        {!isOneLineCheckout && (
          <div className='cta-btn-container'>
            <button className='big-green-btn' disabled={soldOut} onClick={() => handleSubmit()}>
              Next Step <i className='fas fa-chevron-right'></i>
            </button>
            <ShipsInfo />
          </div>
        )}
      </section>
    );
  } else {
    return null;
  }
}

ProductSelect.propTypes = {
  onSubmit: PropTypes.func,
  orderData: PropTypes.object,
  products: PropTypes.array,
};
