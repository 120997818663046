/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import Error from './components/Error/Error';
import Footer from './components/Footer/Footer';
import NoData from './components/NoData/NoData';
import Loading from './components/Loading/Loading';
import ContactUsModal from './components/Modals/ContactUsModal/ContactUsModal';
import PrivacyModal from './components/Modals/PrivacyModal/PrivacyModal';
import TermsModal from './components/Modals/TermsModal/TermsModal';
import UpsellModal from './components/Modals/UpsellModal/UpsellModal';
import SpecialOffer from './containers/SpecialOffer/SpecialOffer';
import ThankYouPage from './containers/ThankYouPage/ThankYouPage';
import { Context as AppContext } from './context/AppContext';
import { Context as OfferContext } from './context/OfferContext';
import { Context as OrderContext } from './context/OrderContext';
import { Context as PromoContext } from './context/PromoContext';
import { Context as ReviewsContext } from './context/ReviewsContext';
import { Context as CrossSellsContext } from './context/CrossSellsContext';
import { Context as FreeProductsContext } from './context/FreeProductsContext';
import { Preloader } from './components/Preloader/Preloader';
import { PageView, initGA } from './utils/tracking';
import { buildQueryParams, getAffiliateCbValue, stringToBase64 } from './utils/helpers';
import './App.sass';
import { trackFacebookAddPaymentInfo, trackFacebookPageView } from './utils/facebook';
import Template3 from './containers/Checkout/Template3';
import Default from './containers/Checkout/Default';
import api from './api/api';

function App() {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = buildQueryParams(location);
  const queryParamsCaseSaved = buildQueryParams(location, true);
  const history = useHistory();

  // Contexts
  const {
    state: { offer, loading: offerLoading, error: offerError },
    loadOffer,
    mergeOfferWithPromo,
  } = useContext(OfferContext);

  const {
    state: { order, error: orderError },
    createOrder,
    updateOrder,
  } = useContext(OrderContext);

  const {
    state: { promo, loading: promoLoading, error: promoError },
    loadPromo,
  } = useContext(PromoContext);

  const {
    state: { reviews, error: reviewsError },
    loadReviews,
  } = useContext(ReviewsContext);

  const {
    state: { crossSells, error: crossSellsError },
    loadCrossSells,
  } = useContext(CrossSellsContext);

  const {
    state: { freeProducts, error: freeProductsError },
    loadFreeProducts,
  } = useContext(FreeProductsContext);

  const { sendMessageFromContactForm } = useContext(AppContext);

  // App state
  const [contactUsModalIsOpen, setContactUsModalOpen] = useState(false);
  const [privacyModalIsOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalIsOpen, setTermsModalOpen] = useState(false);
  const [upsellModalIsOpen, setUpsellModalOpen] = useState(false);
  const [isPreloaderFinished, setPreloaderFinished] = useState(false);
  const [promoWasMerged, setPromoWasMerged] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [declineSettingsError, setDeclineSettingsError] = useState(false);

  const isOneLineCheckout = queryParams.olc === '1';
  const isOneProductCheckout = queryParams.opc === '1' || offer?.template === 2;
  const isSkipModal = queryParams.skipmodal === '1';

  // Set launch_ra_id cookie if not already set
  useEffect(() => {
    if (!Cookies.get('launch_ra_id')) {
      const timestamp = new Date().toISOString();
      const url = window.location.href;
      const uniqueId = `${timestamp}|${url}`;
      const encodedId = Base64.encode(uniqueId);
      Cookies.set('launch_ra_id', encodedId, { expires: 365 });
    }
  }, []);

  useEffect(() => {
    trackFacebookPageView(queryParams);
  }, [location.pathname]);

  // API Requests
  useEffect(() => {
    if (queryParams.offerid) {
      (async () => {
        const offer = await loadOffer(queryParams.offerid);
        await loadFreeProducts(offer._id);

        if (offer.reviews.length > 0) {
          await loadReviews(offer.reviews.map((item) => item.id));
        }
        if (offer.crossSells.length > 0) {
          await loadCrossSells(offer.crossSells.map((item) => item.id));
        }
      })();
    }

    if (queryParams.promoid) {
      (async () => {
        await loadPromo(queryParams.promoid);
      })();
    }

    if (queryParams.preloader === '0') {
      setPreloaderFinished(true);
    }

    if (!window.location.href.includes('thank-you')) {
      localStorage.setItem('initialCheckoutUrl', window.location.href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offer.gtmId) {
      const tagManagerArgs = {
        gtmId: offer.gtmId,
      };

      TagManager.initialize(tagManagerArgs);
    }

    initGA(offer?.gaId);
    PageView();

    if (offer.name) {
      document.title = `Checkout | ${offer.name}`;
    }

    if (offer.faviconSrc) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = offer.faviconSrc;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    // eslint-disable-next-line no-console
    console.log('LAUNCH');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  useEffect(() => {
    // Merging promo with offer only after offer was loaded
    // if we have promo loaded, and offer is loaded, and promo offer id is same as offer.id and promo was not merged yet
    if (promo && offer && Object.keys(offer).length > 0 && promo.offerId === offer._id && !promoWasMerged) {
      mergeOfferWithPromo(promo);
      setPromoWasMerged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo, offer]);

  function submitContactUsForm(data) {
    sendMessageFromContactForm({
      ...data,
      domain: offer.company.website,
      to: `support@${offer.company.website}`,
    });
  }

  async function createStickyProspect(data) {
    try {
      await api.post('/newStickyProspect', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Sticky Prospect error: ', error);
    }
  }

  async function createKlaviyoProspect(data) {
    data.promoid = queryParams.promoid;
    try {
      await api.post('/newKlaviyoProspect', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Klaviyo Prospect error: ', error);
    }
  }

  async function handleCreateOrder(data) {
    const isPayPalOrder = data.creditCardType === 'paypal';

    Cookies.set('last4digits', data.creditCardNumber.slice(-4), { expires: 1 });
    Cookies.set('email', data.email, { expires: 1 });

    try {
      let everflowTransactionId = '';
      window.dataLayer = window.dataLayer || [];

      // for internal traffic we use direct links and generate transaction id from click
      if (window.EF) {
        everflowTransactionId = window.EF.getTransactionId(queryParams.oid || offer.everflowOfferId);
      }

      // for external affiliates we use click_id from url because they have redirect links
      if (queryParams.click_id) {
        everflowTransactionId = queryParams.click_id;
      }

      if (data.billingSameAsShipping) {
        data.billingFirstName = data.shippingFirstName;
        data.billingLastName = data.shippingLastName;
        data.billingAddress1 = data.shippingAddress1;
        data.billingAddress2 = data.shippingAddress2;
        data.billingCity = data.shippingCity;
        data.billingState = data.shippingState;
        data.billingZip = data.shippingZip;
        data.billingCountry = data.shippingCountry;
      }

      const newOrderData = {
        ...data,
        everflowTransactionId,
        userAgent: navigator.userAgent,
        orderSourceUrl: window.location.href,
        tid: offer.gaId,
        cid: window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : '',
        affId: queryParams.affid || Cookies.get('affid'),
        afId: queryParams.affid || Cookies.get('affid'),
        sid: queryParams.sid,
        c1: queryParams.c1 || queryParams.sub1,
        c2: queryParams.c2 || queryParams.sub2,
        c3: queryParams.c3 || queryParams.sub3,
        revContentId: queryParams.rc_uuid,
        click_id: everflowTransactionId,
        offerId: offer._id,
        offerName: offer.name,
        cookie: document.cookie,
        notes: window.location.href,
        utm_medium: queryParams.sub4 || Cookies.get('sub4'),
        everflowCb: getAffiliateCbValue(window.dataLayer),
        taboolaClickId: queryParamsCaseSaved.taboola_click_id,
        mgidClickId: queryParamsCaseSaved.mgid_click_id,
        campaignId: queryParams.cid || data.campaignId,
        alt_pay_return_url: window.location.href,
        promoCode: queryParamsCaseSaved.promo_code,
        gclid: queryParamsCaseSaved.gclid,
      };

      createStickyProspect(newOrderData);
      createKlaviyoProspect(newOrderData);

      const newOrder = await createOrder(newOrderData);

      if (newOrder.declinedByRA) {
        setShowLoading(false);
        setDeclineSettingsError('Your order was declined. Please contact support for more information.');
        return;
      } else {
        setDeclineSettingsError(false);
      }

      // Events that should happen right after order is created.
      // Push custom variable value with revenue for GTM
      window.dataLayer.push({ orderValue: newOrder.orderTotal });
      window.dataLayer.push({
        transactionId: stringToBase64(data.email),
      });

      // send custom Order Created event to Google Tag Manager
      window.dataLayer.push({
        event: 'orderCreated',
      });

      trackFacebookAddPaymentInfo(data, queryParams);

      history.push({
        search: location.search + `&internalorderid=${newOrder._id}`,
      });

      setShowLoading(false);

      if (isPayPalOrder) {
        window.dataLayer.push({
          event: 'paypalOrderIntent',
        });
        localStorage.setItem('internalorderid', newOrder._id);
      }

      if (isSkipModal || isPayPalOrder) {
        handleUpsellModalSubmit(null);
      } else {
        setUpsellModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function redirectToPage(path) {
    history.push({
      pathname: path,
      search: location.search + `&preloader=0`,
    });
  }

  async function handleUpsellModalSubmit(product) {
    // if NO product was selected
    if (!product) {
      setUpsellModalOpen(false);
      // redirect to first upsell or finish the order
      if (offer.specialOfferIds[0]) {
        redirectToPage(`/specialoffer/${offer.specialOfferIds[0]}`);
      } else {
        redirectToPage('/thank-you');
      }
      return;
    }

    // if product was selected
    await updateOrder(queryParams.internalorderid || order._id, {
      offers: [
        ...order.offers,
        {
          billing_model_id: product.billingModelId,
          offer_id: product.offerId,
          product_id: product.stickyId,
          productName: product.name || product.productName,
          isFreeProduct: true,
        },
      ],
      hasWebTv: true,
    });

    setUpsellModalOpen(false);

    // redirect to second upsell or finish the order
    if (offer.specialOfferIds[1]) {
      redirectToPage(`/specialoffer/${offer.specialOfferIds[1]}`);
    } else {
      redirectToPage('/thank-you');
    }
  }

  function renderModals() {
    return (
      <>
        <ContactUsModal
          modalIsOpen={contactUsModalIsOpen}
          closeModal={() => setContactUsModalOpen(false)}
          company={offer.company}
          onFormSubmit={submitContactUsForm}
        />
        <PrivacyModal
          modalIsOpen={privacyModalIsOpen}
          closeModal={() => setPrivacyModalOpen(false)}
          company={offer.company}
        />
        <TermsModal
          modalIsOpen={termsModalIsOpen}
          closeModal={() => setTermsModalOpen(false)}
          company={offer.company}
          onPrivacyClick={() => setPrivacyModalOpen(true)}
        />
        <UpsellModal
          modalIsOpen={upsellModalIsOpen}
          closeModal={() => handleUpsellModalSubmit()}
          onSubmit={handleUpsellModalSubmit}
          freeProducts={freeProducts}
          settings={offer.upsellSettings}
        />
      </>
    );
  }

  if (offerLoading || promoLoading || showLoading) {
    return <Loading />;
  }

  if (offerError || orderError || promoError || reviewsError || freeProductsError) {
    return (
      <Error
        error={offerError || orderError || promoError || reviewsError || freeProductsError || crossSellsError}
      />
    );
  }

  if (!offer || !Object.keys(offer).length) {
    return <NoData />;
  }

  if (!isPreloaderFinished) {
    return (
      <Preloader
        onFinish={() => setPreloaderFinished(true)}
        preloaderInfo={offer.preloader}
        logoSrc={offer.logoSrc}
      />
    );
  }

  function renderCheckout() {
    if (queryParams.template === '3' || offer.template === 3) {
      return (
        <Template3
          offer={offer}
          crossSells={crossSells}
          onCreateOrder={handleCreateOrder}
          onTermsClick={() => setTermsModalOpen(true)}
          onPrivacyClick={() => setPrivacyModalOpen(true)}
          declineSettingsError={declineSettingsError}
        />
      );
    }

    return (
      <Default
        offer={offer}
        reviews={reviews}
        crossSells={crossSells}
        onCreateOrder={handleCreateOrder}
        isOneLineCheckout={isOneLineCheckout}
        isOneProductCheckout={isOneProductCheckout}
        onTermsClick={() => setTermsModalOpen(true)}
        onPrivacyClick={() => setPrivacyModalOpen(true)}
        declineSettingsError={declineSettingsError}
      />
    );
  }

  return (
    <>
      {renderModals()}
      <Switch>
        <Route path='/specialoffer/:id'>
          <SpecialOffer offer={offer} />
        </Route>
        <Route path='/thank-you'>
          <ThankYouPage offer={offer} />
        </Route>
        <Route path='/'>{renderCheckout()}</Route>
      </Switch>

      <Footer
        brand={offer.company?.name}
        openContactUsModal={() => setContactUsModalOpen(true)}
        openPrivacyModal={() => setPrivacyModalOpen(true)}
        openTermsModal={() => setTermsModalOpen(true)}
      />
    </>
  );
}

export default App;
