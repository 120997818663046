/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import { Context as OrderContext } from '../../context/OrderContext';
import { PageView } from '../../utils/tracking';
import { buildQueryParams, getPriceWithCurrency } from '../../utils/helpers';
import './ThankYouPage.sass';

export default function ThankYouPage({ offer }) {
  const { company, brand } = offer;
  const queryParams = buildQueryParams(location);
  const {
    state: { order, loading, error },
    updateOrder,
  } = useContext(OrderContext);

  const isPayPalOrder = order.creditCardType === 'paypal';
  const internalOrderId = queryParams.internalorderid || localStorage.getItem('internalorderid') || '';
  const stickyOrderId = order?.processedResponseData?.order_id || '';
  const total = order?.processedResponseData?.orderTotal || '';
  let errorText = order?.processedResponseData?.error_message;

  useEffect(() => {
    const fetchData = async () => {
      window.dataLayer = window.dataLayer || [];

      const response = await updateOrder(internalOrderId, { flowIsComplete: true });
      const responseCode = response?.processedResponseData?.response_code;

      if (isPayPalOrder) {
        if (responseCode === '100') {
          window.dataLayer.push({
            event: 'paypalOrderSuccess',
          });
        } else {
          window.dataLayer.push({
            event: 'paypalOrderError',
          });
        }
      }

      if (responseCode === '100') {
        if (typeof window['fbq'] !== 'undefined') {
          window.fbq('track', 'Purchase', {
            value: order.processedResponseData.orderTotal * 1,
            currency: 'USD',
            content_type: 'product',
            content_ids: order.offers.map((item) => item.product_id),
            order_id: `order${order.processedResponseData.order_id}`,
            content_category: order.processedResponseData.order_id,
            content_name: order.processedResponseData.order_id,
            search_string: order.processedResponseData.order_id,
            stickyOrderId: order.processedResponseData.order_id,
            external_id: Cookies.get('launch_ra_id'),
          });
        }
      }

      PageView();

      window.dataLayer.push({
        event: 'thankYouPageView',
      });
    };

    fetchData();
  }, []);

  const goBackToCheckout = () => {
    window.location.href = `${localStorage.getItem('initialCheckoutUrl')}&preloader=0`;
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (errorText) {
    return (
      <main className='page-main'>
        <div className='container'>
          <div className='thank-you'>
            <div className='thank-you__title'>
              Sorry, we tried to process your order but something went wrong.
            </div>
            <div className='thank-you__text'>{errorText}</div>
            {localStorage.getItem('initialCheckoutUrl') && (
              <button onClick={goBackToCheckout} className='try-again'>
                <i className='fa fa-backward'></i> Go Back and Try Again
              </button>
            )}
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className='page-main'>
      <div className='container'>
        <div className='thank-you'>
          <div className='thank-you__title'>Thank you for shopping with {brand}</div>
          <div className='thank-you__text'>
            You will receive a confirmation email with a receipt of your recent purchase. If you have any
            questions or concerns, feel free to reach out to us at{' '}
            <span className='inline-block'>{company.email}</span> or by phone{' '}
            <span className='inline-block'>{company.phone}</span>
          </div>
          <div className='thank-you__order-number'>Your order number is - {stickyOrderId}</div>
          <div className='thank-you__order-total'>
            Your total is - {getPriceWithCurrency(total, offer?.locale, offer?.currency)}
          </div>
        </div>
      </div>
    </main>
  );
}
